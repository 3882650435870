/* AboutUs.css */

.about-us-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 900px;
    padding: 20px;
    color: #333;
    font-family: Arial, sans-serif;
}

.about-us-content {
    text-align: justify;
    line-height: 1.6;
    margin-bottom: 30px;
}

.map-container {
    width: 100%;
    height: 50vh;
    margin-bottom: 30px;
}
