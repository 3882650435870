.chat-conversations--pos-right {
  /* right: 20px; */
}

.chat-conversations--side-bottom {
  /* bottom: 20px; */
}

.chat-conversations {
  /* visibility: hidden; */
  /* opacity: 0; */
  position: fixed;
  /* max-height: calc(100% - 40px); */
  /* max-width: calc(100% - 40px); */
  /* -webkit-transition: .2s linear; */
  transition: 0.2s linear;
  -webkit-transition-property: visibility, opacity;
  transition-property: visibility, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: auto;
  height: auto;
  min-height: 0;
  min-width: 0;
  display: block;
  /* -webkit-box-sizing: content-box; */
  box-sizing: content-box;
  padding: 0;
  margin: 0;
  width: 750px;
}

.chat-conversations---visible {
  visibility: visible;
  opacity: 1;
}

.chat-conversations--fast-toggle.chat-conversations--style-round:not(
    .chat-conversations--expanded
  )
  .chat-conversations__wrapper {
  -webkit-animation: chat-conversations-round-button-appear 150ms ease-out 50ms
    both;
  animation: chat-conversations-round-button-appear 150ms ease-out 50ms both;
}

@keyframes chat-conversations-round-button-appear {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.chat-conversations--style-round:not(.chat-conversations--expanded)
  .chat-conversations__wrapper,
.chat-conversations--style-round.chat-conversations--safari:not(
    .chat-conversations--expanded
  )
  .chat-conversations__iframe {
  border-radius: 10%;
}

.chat-conversations:not(.chat-conversations--expanded)
  .chat-conversations__-wrapper {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
}

.chat-conversations__wrapper,
.chat-conversations--safari .chat-conversations__iframe,
.chat-conversations--webkit.chat-conversations--expanded
  .chat-conversations__iframe {
  border-radius: 12px;
}

.chat-conversations__wrapper {
  position: absolute;
  /* -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.1), 0 0 10px rgba(0,0,0,0.3); */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden !important;
}

.chat-conversations__wrapper,
.chat-conversations__iframe {
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
  background: transparent !important;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

svg:not(:root) {
  overflow: hidden;
}

.chat-conversations--expanded {
  width: 550px;
  height: 100%;
}

.chat-conversations--visible {
  width: 60px;
  height: 60px;
}

.main {
  height: 100%;
  overflow: hidden;
}

.container-chat {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

#main.is-widget .container__inner {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  background-color: var(--chat-bg-color);
  overflow: hidden;
}

.header__round-button-icon-text {
  font-size: 16px;
  text-align: center;
  margin: 0;
  font-weight: bold;
}

.container__inner {
  height: 100%;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 15;
}

.container__header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

#main.is-chat-closed .header {
  min-height: 40px;
  line-height: 40px;
  border-bottom: 0;
}

/* #main.is-widget .header {
  -webkit-animation: header-appear .25s ease-out both;
  animation: header-appear .25s ease-out both;
  display: none;
} */
.header__inner {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 1em;
  /* width: 100%; */
}

#main.is-chat-closed.is-widget.is-style-round .header__logo,
#main.is-chat-closed.is-widget.is-style-round .header__welcome-text {
  display: none;
}

.header__close {
  position: absolute;
  top: 50%;
  right: 0;
  height: 40px;
  margin-top: -20px;
  padding: 0 20px;
  font-weight: 700;
  outline: 0;
  background: transparent;
  border: 0;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  opacity: 0.6;
  vertical-align: middle;
  word-wrap: normal;
  visibility: hidden;
  z-index: -1;
}

.header__close-icon {
  width: 8px;
  height: 8px;
  fill: currentColor;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.container__main-wrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  position: relative;
  display: none;
}

#main.is-chat-closed .container__main-wrapper {
  -webkit-transition: none;
  transition: none;
}

.container__main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

#main.is-widget.is-style-round.is-chat-closed .header {
  width: 100%;
  min-height: 100%;
  line-height: 0;
}

#main.is-chat-closed.is-widget.is-style-round .header {
  position: absolute;
}

#main.is-widget.is-style-round.is-chat-closed .header__round-button-icon,
#main.is-widget.is-style-round.is-fully-closed:not(.is-transparent) .header,
#main.is-widget.is-style-round.is-fully-opened:not(.is-transparent) .header {
  display: block;
}

.header__round-button-icon {
  position: absolute;
  display: none;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  fill: currentColor;
  pointer-events: none;
}

html.chrome .container__header .header,
html.opera .container__header .header {
  -webkit-transition: -webkit-transform 0s linear 0.4s;
  transition: -webkit-transform 0s linear 0.4s;
  transition: transform 0s linear 0.4s;
  transition: transform 0s linear 0.4s, -webkit-transform 0s linear 0.4s;
  z-index: 18;
}

.chat-and-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  -webkit-transition-property: opacity, visibility;
  transition-property: opacity, visibility;
}

html.chrome .chat-and-input__chat,
html.opera .chat-and-input__chat {
  -webkit-transition: -webkit-filter 0.4s;
  transition: -webkit-filter 0.4s;
  transition: filter 0.4s;
  transition: filter 0.4s, -webkit-filter 0.4s;
}

.chat-and-input__chat {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-height: calc(100% - 3.6em);
  position: relative;
}

.chat-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.custom-scrollbar__recipient {
  box-sizing: border-box;
}

.chat-scroller {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

html.no-hiddenscroll .chat-shaft {
  padding-right: 1.2em;
}

.chat-shaft {
  padding: 1em 1.2em;
  position: relative;
  overflow: hidden;
  padding-right: 1.2em;
}

html:not(.gecko) .chat-shaft__inner {
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.chat-shaft__inner {
  position: relative;
  opacity: 1;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.header__welcome-text:before {
  content: "";
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  line-height: 8px;
  margin-right: 6px;
  border-radius: 5px;
  vertical-align: 0.1em;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.8);
}

.header__welcome-text {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__welcome-text > p {
  margin: 0;
}

.header--online .header__welcome-text:before {
  background-color: #51d161;
  background-image: -webkit-linear-gradient(left top, #80dc7a, #23c748);
  background-image: linear-gradient(to right bottom, #80dc7a, #23c748);
}

html.chrome .container__header .header,
html.opera .container__header .header {
  -webkit-transition: -webkit-transform 0s linear 0.4s;
  transition: -webkit-transform 0s linear 0.4s;
  transition: transform 0s linear 0.4s;
  transition: transform 0s linear 0.4s, -webkit-transform 0s linear 0.4s;
  z-index: 18;
}

.header {
  outline: 0;
  min-height: 40px;
  line-height: 40px;
  display: block;
  width: 100%;
  text-align: left;
  padding: 0;
  position: relative;
  z-index: 15;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  max-width: 100vw;
}

#main.is-widget.is-fully-opened .header__close {
  visibility: visible;
  z-index: 0;
}

.chat-welcome {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-animation: show 0.3s ease-out;
  animation: show 0.3s ease-out;
  z-index: 10;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

#main.is-chat-opened .header {
  padding: 0.5em 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2),
    0 -1px 10px var(--chat-button-shadow-color);
}

.chat-welcome__shaft {
  padding: 1em 0.8em 3em;
}

.chat-faces {
  text-align: center;
  padding: 1.5em 1em;
  position: relative;
  margin: 0 auto;
  max-width: 30em;
}

#main.is-fully-opened .container__main-wrapper {
  display: block;
}

.chat-faces__face {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  position: relative;
  box-sizing: border-box;
  padding: 0 0.3em;
}

.chat-faces__userpic {
  display: inline-block;
  position: relative;
}

.userpic {
  position: relative;
  width: 50px;
  height: 50px;
  line-height: 30px;
}

/* .userpic.is-loaded .userpic__inner {
  background-color: #fff;
  background-color: var(--chat-bg-color);
} */

.userpic__inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  background-color: #f1f3f5;
}

.userpic.is-loaded .userpic__text {
  visibility: hidden;
}

.userpic__text {
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #1b1b1b;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chat-faces__face--100 .chat-faces__status {
  bottom: 8px;
  right: 8px;
}

.chat-faces__status {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  border: 2px solid #fff;
  background-color: #51d161;
  background-image: -webkit-linear-gradient(left top, #80dc7a, #23c748);
  background-image: linear-gradient(to right bottom, #80dc7a, #23c748);
}

.chat-faces__name {
  line-height: 1.3;
}

.chat-welcome-message {
  padding: 1.5em 1em;
  text-align: center;
  position: relative;
}

.userpic__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.container__header .js-header {
  background-size: 200% 200%;
  animation: gradientAnimation 30s ease infinite alternate;
}

.custom-scrollbar__caret {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
  padding: 10px 5px;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  z-index: 15;
}

.custom-scrollbar__caret-inner {
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-sizing: border-box;
}

.chat-wrapper.is-visually-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

#main:not(.is-under-shown) .chat-and-input__input {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.chat-and-input__input {
  z-index: 30;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.input-area-wrapper {
  position: relative;
  padding: 0;
}

.input-area {
  width: 100%;
  display: table;
  -webkit-transition: -webkit-transform 0.2s ease-out 0.05s;
  transition: -webkit-transform 0.2s ease-out 0.05s;
  transition: transform 0.2s ease-out 0.05s;
  transition: transform 0.2s ease-out 0.05s,
    -webkit-transform 0.2s ease-out 0.05s;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 0 3px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: relative;
  padding: 0.3em 1em;
  box-sizing: border-box;
  z-index: 2;
}

.input-area__row {
  display: table-row;
}

.input-area__input {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chat-textarea.is-empty {
  white-space: nowrap;
  overflow-x: hidden;
}

.chat-textarea {
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  height: 3.1em;
  min-height: 3.1em;
  padding: 0.8em 0.5em;
  border: 0;
  background-color: transparent;
  vertical-align: middle;
  resize: none;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: color 0.4s;
  transition: color 0.4s;
}

.input-area__button-cell {
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
  color: #bebec3;
  white-space: nowrap;
  visibility: visible;
  -webkit-transition: color 0.15s linear, opacity 0.4s ease,
    visibility 0.4s linear, -webkit-transform 0.4s ease;
  transition: color 0.15s linear, opacity 0.4s ease, visibility 0.4s linear,
    -webkit-transform 0.4s ease;
  transition: color 0.15s linear, transform 0.4s ease, opacity 0.4s ease,
    visibility 0.4s linear;
  transition: color 0.15s linear, transform 0.4s ease, opacity 0.4s ease,
    visibility 0.4s linear, -webkit-transform 0.4s ease;
}

.input-area__button-cell.is-hidden {
  display: none;
}

.input-area__button {
  background: transparent;
  border: 0;
  padding: 0;
  height: 100%;
}

.input-area__button-icon {
  width: 1.8em;
  height: 1.8em;
  fill: currentColor;
  vertical-align: middle;
  pointer-events: none;
}

.input-area__button-cell--submit:hover .input-area__button-icon {
  color: darkgreen;
  /* Specify the color you want on hover */
}

.input-area.is-empty .input-area__button-cell--submit,
.input-area:not(.is-empty) .input-area__button-cell--attachment,
.input-area:not(.is-empty) .input-area__button-cell--rate {
  display: none;
}

.chat-textarea.is-empty {
  white-space: nowrap;
  overflow-x: hidden;
}

.chat-textarea:focus {
  border: none;
  /* Removes the border */
  outline: none;
  /* Removes the default outline */
}

.chat-message.is-user {
  display: block;
}

.chat-message {
  padding: 0.5em 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
}

.chat-message.is-user .chat-message__content {
  margin-left: 30px;
  padding-left: 0.5em;
}

.chat-message__content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.chat-message__bubble-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
}

.chat-message.is-user .chat-message__bubble {
  --local-bg: var(--user-bubble-bg);
}

.chat-message.is-user .chat-message__bubble {
  margin-left: auto;
}

.chat-message.is-first.is-user .chat-message__bubble {
  border-bottom-right-radius: 0;
}

.chat-message__bubble {
  box-sizing: border-box;
  position: relative;
  border-radius: 12px;
  -webkit-transition: border-radius 0.2s;
  transition: border-radius 0.2s;
  max-width: 100%;
  max-height: 100%;
}

.chat-bubble--user {
  color: #1b1b1b;
  color: var(--user-bubble-text);
  background-color: #d4f3fc;
  background: linear-gradient(to right, #474bf3d1, #007bff);
}

.chat-bubble {
  position: relative;
  clear: both;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: 0.1s linear;
  transition: 0.1s linear;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  box-sizing: border-box;
  max-width: 450px;
}

.chat-bubble__inner {
  padding: 0.6em 1em;
}

@supports (--foo: #008000) and
  (
    (-webkit-filter: brightness(0.1) contrast(0.1) saturate(0.1)) or
      (filter: brightness(0.1) contrast(0.1) saturate(0.1))
  ) {
  .parsed-text--dark-bg {
    color: #fefdff;
  }
}

.chat-bubble__message-text {
  min-width: 0;
}

.chat-message.is-user {
  display: block;
}

.chat-message.is-group {
  padding-top: 1px;
  margin-top: -0.5em;
}

.chat-message__userpic {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.7em;
  vertical-align: top;
  position: relative;
  text-align: center;
  cursor: default;
  margin-right: 0.5em;
  /* width: 30px; */
}

.userpic.is-loaded .userpic__text {
  visibility: hidden;
}

.userpic--message .userpic__text {
  font-size: 0.7em;
}

.chat-message.is-assistant .chat-message__content {
  padding-right: 2em;
}

.chat-message.is-assistant:not(.is-notification) .chat-message__bubble {
  --local-bg: var(--agent-bubble-bg);
}

.custom-scrollbar__recipient:hover ~ .custom-scrollbar__caret.is-active {
  opacity: 0.7;
}

.chat-bubble--assistant {
  background-color: #f1f3f5;
  background-color: var(--agent-bubble-bg);
}

.chat-message__replies {
  /* width: 100%; */
  padding-right: 2em;
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
  flex-wrap: wrap;
}

.reply-pill {
  margin-top: 20px;
  padding: 5px 15px;
  background-color: #e3e1e1;
  color: rgb(0, 0, 0);
  font-weight: bold;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.reply-pill:hover {
  background-color: #dcdada;
}

.chat-bubble--typing {
  background-color: f1f3f5;
  padding: 5x;
  -webkit-border-radius: 2px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 2px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-image: linear-gradient(to right, #f94f75, #027bff);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-image: linear-gradient(to right, #ffa5b8, #88c1fd);
  }

  28% {
    transform: translateY(-7px);
    background-image: linear-gradient(to right, #f77490, #7eb1e9);
  }

  44% {
    transform: translateY(0px);
    background-image: linear-gradient(to right, #f94f75, #027bff);
  }
}

.chat-and-input__chat {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-height: calc(100% - 3.6em);
  position: relative;
}

.userpic__video {
  width: 50px;
  /* Set the width of the video */
  height: 50px;
  /* Set the height of the video to match the width for a circle */
  border-radius: 50%;
  /* This makes the video circular */
  object-fit: cover;
  /* Ensures the video covers the circular area without being stretched */
  overflow: hidden;
  /* Ensures nothing extends outside the circular boundary */
  border: 2px solid white;
  /* Optional: Adds a border around the video. Adjust color as needed */
}

.custom-scrollbar__caret.is-active {
  cursor: -webkit-grab;
  cursor: grab;
  visibility: visible;
  opacity: 0.7;
}

.custom-scrollbar__recipient:hover ~ .custom-scrollbar__caret.is-active {
  opacity: 0.7;
}

.chatbox-files-bottom-bar-chatbox {
  display: flex;
  background-color: rgb(219, 219, 54);
}

.file-uploading-container.file-uploading-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview-container.file-preview-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview.file-chatbox-preview,
.file-preview.uploading-file-preview {
  box-sizing: border-box;
  min-width: 140px;
  min-height: 100px;
  max-width: 140px;
  max-height: 100px;
  margin-bottom: 0px;
  background-color: #fff;
}

.chat-input-main-elements-chatbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-input-bottom-container-chatbox {
  /* background-color: lightblue;
  overflow: scroll; */
  max-width: 100%;
}

.chatbox-chat-attachments-chatbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
}

.chatbox-chat-attachments-chatbox > .file-preview {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.loader-chat-loading-chatbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-chat-loading-dot-chatbox {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #cac7c7;
  /* or any color you prefer */
  border-radius: 50%;
  animation: jump 0.6s infinite;
}

.loader-chat-loading-dot-chatbox:nth-child(1) {
  animation-delay: 0.1s;
}

.loader-chat-loading-dot-chatbox:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-chat-loading-dot-chatbox:nth-child(3) {
  animation-delay: 0.3s;
}

.attachment-preview-chatbox {
  position: relative;
  margin-top: 10px;
}

.stacked-thumbnails-chatbox {
  display: flex;
  align-items: flex-end;
}

.file-thumbnail-preview-chatbox {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: -100px;
  /* Negative margin to create the stack effect */
}

.file-thumbnail-preview-chatbox:last-child {
  margin-right: 0;
  /* No negative margin for the last item */
}

.preview-image-chatbox {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-icon-chatbox {
  width: 50%;
  height: 50%;
  object-fit: cover;
}

.file-thumbnail-preview-chatbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 84px;
  height: 40px;
}

.overlay-stacked-files-chat-chatbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  /* background-color: rgb(219 244 215 / 80%); */
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  /* Adjust as needed */
  font-weight: bolder;
  /* Adjust as needed */
  border-radius: 4px;
  /* Optional, if you want rounded corners */
}

.show-more-attachments-text-chatbox {
  width: 132px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-weight: bolder;
}

.show-more-attachments-text-chatbox > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.chat-input-main-elements-chatbox,
.chat-input-main-elements-mobile-chatbox {
  position: relative;
  /* This ensures the emoji picker is positioned relative to this element */
}

.date-time-selector {
  width: 80%;
}

@media (min-width: 900px) {
  .emoji-picker-react {
    position: absolute;
    bottom: 120%;
    /* Places the bottom of the emoji picker right above the toggle button */
    left: 0%;
    /* Aligns the emoji picker to the right; adjust as needed */
    z-index: 100;
    /* Ensures it appears over other elements */
  }
}

@media (max-width: 900px) {
  .emoji-picker-react {
    position: absolute;
    bottom: 100%;
    /* Adjust as needed to position above the button */
    left: 50%;
    /* Horizontally center within the parent container */
    transform: translateX(-50%);
    /* Adjust for the picker's own width */
    z-index: 100;
    /* Ensure it appears over other elements */
  }

  .emoji-picker-react input {
    font-size: 16px;
  }
}

.file-info-download-bar {
  background-color: #f5f5f5;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.file-info-download-bar:hover {
  cursor: pointer;
}

.file-info-download-bar > * {
  margin: 0;
}

.file-info-download-bar > p {
  color: black;
  font-size: 11px;
}

.chat-message.is-user::after {
  content: "";
  position: absolute;
  bottom: 10px;
  right: -35px;
  border-width: 5px 5px 10px 10px;
  border-style: solid;
  border-color: transparent transparent transparent var(--user-gradient-end);
  transform: rotateZ(22deg) translateY(70%) translateX(-23px);
}

/* .chat-message.is-assistant::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -35px;
  border-width: 15px 20px 10px 25px;
  border-style: solid;
  border-color: transparent #f1f3f5 transparent transparent;
  transform: rotateZ(-5deg) translateY(-20%) translateX(61px);
} */

.chatbot-service-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.chatbot-service-card > div {
  flex-grow: 1;
  /* Allows the div to grow and fill the container */
  flex-basis: 0;
  /* Sets the initial length of the div to 0, ensuring equal distribution */
}

.chatbot-service-image {
  width: 120px;
  /* Sets the width of the image */
  border-radius: 8px;
  /* Adds rounded edges to the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow for depth */
}

.chatbot-service-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chatbot-service-info > span {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chatbot-service-info > span > * {
  margin: 0;
}

.chatbot-service-card {
  background-color: #ffffff;
  /* Light background for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  /* Spacing inside the card */
  margin: 15px;
  /* Spacing outside the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition for hover effects */
}

/* Hover effect to lift the card slightly and deepen its shadow */
.chatbot-service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.chatbot-service-card p,
h3 {
  color: rgb(83, 81, 81);
  /* Sets the text color */
  white-space: nowrap;
  /* Prevents text wrapping */
  overflow: hidden;
  /* Hides text overflow */
  text-overflow: ellipsis;
  /* Adds an ellipsis (...) to text that overflows */
}

.chatbot-service-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.chatbot-service-actions > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 0.9rem;
  font-weight: bolder;
  cursor: pointer;
  color: rgb(38, 38, 38);
  background-color: white;
  height: 40px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 5px;
  padding: 0px 10px;
  white-space: nowrap;
  width: 100%;
}

.chatbot-service-actions > button:first-child {
  color: rgb(43, 42, 42);

  border: 1.5px solid rgb(79, 75, 75);
}

.chatbot-service-actions > button:nth-child(2) {
  color: white;
  background-color: rgb(43, 42, 42);
}

.chatbot-service-image-and-title {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.chatbot-service-image-and-title > h3 {
  width: 100px;
  margin: 0;
  white-space: normal;
}

.chatbot-service-detail-image {
  width: 100%;
  margin-top: 15px;
  border-radius: 8px;
  /* Adds rounded edges to the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chatbot-service-book {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 1rem;
  font-weight: bolder;
  cursor: pointer;
  color: rgb(38, 38, 38);
  background-color: white;
  height: 40px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 5px;
  /* width: 120px; */
  color: white;
  background-color: rgb(43, 42, 42);
  padding: 0 10px;
}

.chatbot-service-book:hover {
  background-color: rgb(23, 23, 23);
}

.summary-box {
  width: 60%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 20px 0;
  background-color: white;
}

.checkout-form-content.calendar {
  display: flex;
  justify-content: center;
}

.summary-label > p {
  margin: 0;
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  gap: 10px;
}

.summary-edit {
  display: flex;
  justify-content: center;
}

@media (min-width: 900px) {
  .checkout-form-content.calendar > div {
    display: flex;
    flex-direction: column;
    width: auto;
    width: 100%;
    justify-content: center;
  }
}

.checkout-input {
  width: 100%;
  padding: 13px 11px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.checkout-input:focus {
  outline: none;
  border-color: #5c6ac4;
}

.generate-otp-button {
  color: rgb(255, 255, 255);
  font-weight: 600;
  /* background-color: #5c6ac4;
    background-color: #045ee6e7; */
  border: none;
  width: 200px;
  border-radius: 10px;
}

.generate-otp-button.email-verified {
  background-color: black;
  color: white;
}

.email-edit-button:hover {
  cursor: pointer;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.form-row > * {
  min-width: 0;
  flex: 1 1;
  padding: 13px 11px;
  margin: 8px 0;
  margin-right: 7px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.Toastify__toast-container {
  position: static !important;
}

.Toastify__toast-container--top-center {
  top: 1em;
  transform: translateX(0) !important;
  width: 100% !important;
}

.Toastify {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-spotpicker-dates {
  padding-bottom: 0px !important;
}

@media (max-width: 768px) {
  .chat-conversations--expanded {
    width: 100%;
    max-height: 100%;
    bottom: 0px;
    right: 0px;
  }

  .chat-conversations__wrapper {
    border-radius: 0px;
  }

  .date-time-selector {
    width: 80% !important;
  }

  .spotpicker-time-list {
    overflow: auto !important;
  }

  .chatbot-service-detail-image {
    width: 100%;
  }

  .chatbot-service-detail-info img {
    width: 250px;
    height: auto;
  }
}

@media (max-width: 550px) {
  .chat-message.is-user {
    margin-right: 10px;
  }
  .chatbot-service-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 15px 5px;
  }

  .chat-bubble__inner {
    padding: 0.6em 0.8em;
  }

  .chatbot-service-info {
    /* display: none; */
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .chatbot-service-image-and-title {
    flex-direction: row;
    gap: 10px;
  }

  .chatbot-service-actions {
    flex-direction: row;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
  }

  .chatbot-service-image-and-title {
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }

  .chat-message {
    padding: 0.5em 0;
    position: relative;
    display: flex;
    max-height: 100%;
    /* flex-direction: column; */
    gap: 0px;
  }

  .chat-message.is-assistant .chat-message__content {
    padding-right: 1em;
  }

  .userpic {
    position: relative;
    width: 35px;
    height: 35px;
    line-height: 30px;
  }

  .chat-shaft {
    padding: 0.5em;
    position: relative;
    overflow: hidden;
    /* padding-right: 1em; */
  }

  .rdp {
    --rdp-cell-size: 30px !important;
  }

  .userpic__video {
    width: 35px;
    /* Set the width of the video */
    height: 35px;
    /* Set the height of the video to match the width for a circle */
    border-radius: 50%;
    /* This makes the video circular */
    object-fit: cover;
    /* Ensures the video covers the circular area without being stretched */
    overflow: hidden;
    /* Ensures nothing extends outside the circular boundary */
    border: 2px solid white;
    /* Optional: Adds a border around the video. Adjust color as needed */
  }

  .main-calendar-container {
    width: 100%;
    margin: 20px 0 0 0;
  }

  .form-row {
    flex-direction: column;
    margin-top: 0;
    margin-right: 0;
  }

  .form-row > * {
    width: 100%;
  }

  .checkout-form-content.calendar {
    display: flex;
    justify-content: center;
  }

  .summary-box {
    width: 60%;
  }

  .checkout-user-calendar {
    width: 100%;
  }
}

.checkout-user-calendar {
  width: 100%;
}

.chat-intro-screen {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #4b4ff2 0%, white 70%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.chat-intro-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chat-intro-header:hover {
  cursor: pointer;
}

.chat-intro-message {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.chat-intro-message > * {
  margin: 0;
  padding: 0;
}

.chat-intro-quick-messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}

.chat-intro-message > h1 {
  font-family: "Lato", sans-serif; /* Set the font family */
  font-weight: 900; /* Lato Black for the thickest weight */
}

.chat-intro-quick-messages-containers {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  /* This is enough as it overrides the previous justify-content */
  align-items: center;
  background-color: white;
  border-radius: 12px;
  /* Corrected typo here */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  /* Subtle shadow */
  padding: 20px;
  /* Added some padding for internal spacing */
}

.chat-intro-quick-messages-containers > * {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.chat-intro-quick-messages-containers:hover {
  cursor: pointer;
  transform: scale(1.01);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-image-container {
  display: flex;
  align-items: center;
}

.chatbot-bundle-service-image:first-child {
  margin-left: 0;
}

.chatbot-bundle-service-image {
  width: 80px;
  object-fit: cover;
  border-radius: 5px; /* Optional: for rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional: for shadow effect */
  margin-left: -45px; /* Adjust as needed to get the desired overlap */
}

.chatbot-bundle-service-image:first-of-type {
  margin: 0;
}

.chatbot-bundled-service-badge {
  background-color: #00a040;
  color: white;
  padding: 5px 10px;
  transform: translate(0%, -100%);
  transform-origin: 0 0;
  font-size: 14px;
  white-space: nowrap;
  z-index: 999;
  border-radius: 10px;
  font-weight: bolder;
  color: #ffffff;
  position: absolute;
}

.chatbot-bundled-images {
  margin: 10px 0;
}

.chat-scroller::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.chat-scroller {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}
