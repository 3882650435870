.fade-in {
  transition: opacity 0.5s ease-in-out;
}



.featured-products {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
}

.main-title {
  font-size: 3em;
  margin-bottom: 30px;
  color: #333;
  text-align: center;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.featured-title {
  font-size: 2em;
  color: #333;
  padding-left: 20px; 
}

.see-all-button {
  color: #4b4ff2;
  text-decoration: none;
  font-weight: bold;
}

.services-container {
  display: flex;
  overflow-x: auto;
  padding-left: 20px;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.services-container::-webkit-scrollbar {
  display: none;
}

.service-card {
  flex: 0 0 auto;
  width: 250px;
  height: auto; /* Changed from 300px to auto */
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  padding: 10px;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  flex-direction: column;
  align-items: center;
}


.service-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px; /* Reduced border-radius */
  margin-bottom: 10px;
}

.service-name-link {
  text-decoration: none;
  color: inherit;
  display: block;
}
.service-name {
  font-size: 1.2em;
  margin: 16px 0 0;
  color: #333;
  text-transform: capitalize;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.service-price {
  font-size: 1em;
  margin: 0 0 16px;
  color: #666;
}

.service-name:hover {
  text-decoration: underline;
}

.category-section {
  margin-bottom: 2em;
}

.divider {
  border-top: 1px solid #ddd;
  padding-top: 2em;
}

.carousel .control-arrow:before {
  box-shadow: none;
}
  .service-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .price-and-add-to-cart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .add-to-cart-button {
    border: 1px solid #4b4ff2;
    background-color: transparent;
    color: #4b4ff2;
    padding: 5px;
    cursor: pointer;
    width:60px;
  }


  .quantity-container-all-services {
    display: flex;
    align-items: center;
  }

  .quantity-button-all-services{
    border: 1px solid #4b4ff2;
    background-color: transparent;
    color: #4b4ff2;
    cursor: pointer;
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #f5f5f5;
    cursor: pointer;
  }


  .service-price {
    margin: 0;
  }
  @media screen and (max-width: 480px) {
.service-image{
  height:200px;
  width:100%;
  object-fit: cover;
}

.featured-title {
  font-size: 1.3em;
  color: #333;
  padding-left: 20px; 
}

  }
  
  