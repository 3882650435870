.main-catalog{
  z-index: 1;
  min-height: 100vh;
  height: 100%;
}


.catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  min-height: 100vh;
}

.main-title-catalog {
  color: #333;
  font-size: 36px;
  margin: 20px 0;
}

.sort-container {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.sort-label {
  margin-right: 10px;
  font-size: 18px;
  color: #555;
}

#sort-category {
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.services-container-catalog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card-catalog {
  width: 200px;
  border: 1px solid #eee;
  padding: 15px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
}

.service-card-catalog:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-image-catalog {
  max-width: 100%;
  height: auto;
}

.service-name-catalog {
  font-size: 18px;
  color: #333;
}

.service-price-catalog {
  font-size: 16px;
  color: #555;
}
