.footer {
  background-color: #333;
  color: white;
  padding: 40px 0;
  position: relative; /* or 'static' which is the default */
  margin-top: auto;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-nav {
  margin-bottom: 20px;
}

.footer-menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.footer-menu-item {
  margin-right: 20px;
}

.footer-menu-link {
  text-decoration: none;
  color: white;
}

.footer-social-links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.social-link {
  margin: 0 10px;
  color: white;
  font-size: 24px;
}

.social-link:hover, .social-link:focus {
  color: #ddd;
  text-decoration: none;
}

@media (max-width: 600px) {
  .footer-menu {
   
    align-items: center;
  }

  .footer-menu-item {
    margin-bottom: 10px;
  }

  .footer-social-links {
    align-items: center;
  }

  .social-link {
    margin: 5px;
  }
}
