body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Assistant', sans-serif;
  min-height: 100vh;
  margin: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column; /* Stack flex items vertically */
  min-height: 100vh; /* Full viewport height */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root {
  --font-body-family: Assistant, sans-serif;
  --font-body-style: normal;
  --font-body-weight: 400;
  --font-body-weight-bold: 700;

  --font-heading-family: Assistant, sans-serif;
  --font-heading-style: normal;
  --font-heading-weight: 400;
  --font-body-scale: 1.0;
  --font-heading-scale: 1.0;
  --buttons-radius: 0px;
    --buttons-radius-outset: 0px;
    --buttons-border-width: 1px;
    --buttons-border-opacity: 1.0;
    --buttons-shadow-opacity: 0.0;
    --buttons-shadow-visible: 0;
    --buttons-shadow-horizontal-offset: 0px;
    --buttons-shadow-vertical-offset: 4px;
    --buttons-shadow-blur-radius: 5px;
    --buttons-border-offset: 0px;
    --inputs-radius: 0px;
    --inputs-border-width: 0.5px;
    --color-foreground: 18,18,18;
    --inputs-radius-outset: 0px;
    --inputs-shadow-horizontal-offset: 0px;
    --inputs-shadow-vertical-offset: 4px;
    --inputs-shadow-opacity: 0.0;
    --inputs-shadow-blur-radius: 5px;
    --color-shadow: 18,18,18;
    --color-background: 255,255,255;
    --inputs-border-opacity: 0.55;
    --duration-short: .1s;
    
}


:root {
  --chat-button-bg-color: #4b4ff2;
  --chat-button-shadow-color: rgba(75,79,242, .3);
  --chat-button-text-color: #ffffff;
  --client-bubble-bg:#6769de;
  --client-transparent-bubble-bg: rgba(75,79,242, 0.08);
}

:root {
  --chat-bg-color: #fff;
  --chat-transparent-bg-color: hsla(0,0%,100%,0);
  --client-transparent-bubble-bg: rgba(212,243,252,0.08);
  --client-bubble-bg: #d4f3fc;
  --agent-bubble-bg: #f1f3f5;
}