body {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
  margin: 0;
  letter-spacing: 0.05rem;
  font-weight: var(--font-body-weight);
}
.main-cart {
  min-height: 100vh;
  box-shadow: none !important;
  outline: 0 !important;
  letter-spacing: 0.06rem;
  font-weight: 400;
  color: rgba(18, 18, 18, 0.75);
}

.whole-div-first-main {
  display: flex;
  justify-content: center;
}

.whole-div-cart {
  padding-top: 27px;
  padding-bottom: 27px;
  width: 80%;
}

/* @media (min-width:750px){
  .page-width{
    padding:0 5rem;
  }
} */

@media (min-width: 750px) {
  .whole-div-cart {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}
.cart-items-upper {
  display: block;
}

/* title and wrapper */
.title,
.title-wrapper-with-link {
  margin: 3rem 0 2rem;
}
.title-wrapper-with-link .title {
  margin: 0;
  font-size: calc(var(--font-heading-scale) * 2rem);
}
.title-wrapper-with-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.title-wrapper-with-link a {
  margin-top: 0;
  flex-shrink: 0;
  font-size: 0.9rem;
}

.underlined-link {
  color: rgba(18, 18, 18, 0.85);
  text-underline-offset: 0.3rem;
  text-decoration-thickness: 0.1rem;
  transition: text-decoration-thickness ease 0.1s;
}

/* if cart empty */
.cart-warnings {
  display: block;
  text-align: center;
  padding: 3rem 0 1rem;
  min-height: 100vh;
}

.cart-empty-text {
  margin: 4.5rem 0 2rem;
  font-size: 40px;
}

.button {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  /* padding: 0 3rem; */
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: box-shadow var(--duration-short) ease;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(18, 18, 18, 1);
}

/* .button{
  min-width: calc(12rem + var(--buttons-border-width) * 2);
  min-height: calc(4.5rem + var(--buttons-border-width) * 2);
} */

/* The form and main cart */
@media (min-width: 750px) {
  .cart-items {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 4rem;
  }
}

.cart-items {
  position: relative;
  padding-bottom: 3rem;
  border-bottom: 0.1rem solid rgba(18, 18, 18, 0.08);
}

@media (min-width: 750px) {
  .table-cart-items {
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: none;
    width: 100%;
    display: table;
  }
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

/* 3 rows div service quantity total */
@media (min-width: 750px) {
  .table-cart-items thead th:first-child {
    width: 50%;
  }
}

.table-cart-items td,
.table-cart-items th {
  padding: 0;
  border: none;
}
@media (min-width: 750px) {
  .table-cart-items th {
    border-bottom: 0.1rem solid rgba(18, 18, 18, 0.08);
  }
}

.table-cart-items th {
  text-align: left;
  padding-bottom: 1.8rem;
  opacity: 0.85;
  font-weight: 400;
}

.table-cart-items *.right {
  text-align: right;
}

@media (min-width: 750px) {
  .table-cart-items th + th {
    padding-left: 4rem;
  }
}

.table-cart-items thead th {
  text-transform: uppercase;
}

.caption-with-letter-spacing {
  font-size: 1rem;
  letter-spacing: 0.13rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .medium-hide {
    display: none !important;
  }
}
@media screen and (max-width: 749px) {
  .small-hide {
    display: none !important;
  }
}
@media screen and (min-width: 990px) {
  .large-up-hide {
    display: none !important;
  }
}

@media (min-width: 750px) {
  .table-cart-items .cart-items__heading--quantity {
    padding-left: 5rem;
  }
}

@media (max-width: 749px) {
  .whole-div-cart {
    width: 90%;
  }
  .table-cart-items tbody {
    display: block;
    width: 100%;
  }
}

@media (min-width: 750px) {
  .cart-item {
    display: table-row;
  }
}

@media (min-width: 750px) {
  .table-cart-items td {
    vertical-align: top;
    padding-top: 4rem;
  }
}

.cart-item__link {
  display: block;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.cart-item__image-container {
  display: inline-flex;
  align-items: flex-start;
}

.gradient {
  background: #ffffff;
  background-attachment: fixed;
}

.global-media-settings {
  position: relative;
  border: 1px solid rgba(18, 18, 18, 0.05);
  border-radius: 0px;
  overflow: visible;
  background-color: rgb(255, 255, 255);
}

@media (min-width: 750px) {
  .cart-item__image {
    max-width: 100%;
  }
}

.cart-item__image {
  height: auto;
  max-width: calc(4.2rem / var(--font-body-scale));
}

@media (min-width: 750px) {
  .cart-item > td + td {
    padding-left: 4rem;
  }
}

@media (min-width: 750px) {
  .cart-item__details {
    width: 35rem;
  }
}
.cart-item__name {
  text-decoration: none;
  display: block;
}

.cart-item__details > * {
  margin: 0;
  max-width: 30rem;
}

.h4 {
  font-style: var(--font-heading-style);
  font-size: calc(var(--font-heading-scale) * 1.5rem);
}

.product-option {
  font-size: 1.4rem;
  word-break: break-word;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
}

.cart-item__details > * + * {
  margin-top: 0.6rem;
}

/* for mobile devices */
.cart-item__totals {
  position: relative;
}

.cart-item .loading-overlay {
  top: 0;
  left: auto;
  right: auto;
  bottom: 0;
  padding: 0;
}

@media (min-width: 750px) {
  .cart-item .loading-overlay {
    right: 0;
    padding-top: 4.5rem;
    bottom: auto;
  }
}

.loading-overlay {
  position: absolute;
  z-index: 1;
  width: 1.8rem;
}

.hidden {
  display: none !important;
}

/* Quantity */
@media (min-width: 750px) {
  .cart-item .cart-item__quantity {
    padding-left: 3rem;
  }
}

.quantity-popover {
  position: relative;
  display: block;
}

.cart-item__quantity-wrapper {
  display: flex;
}

.quantity-popover-container:not(.quantity-popover-container--hover) {
  align-items: center;
}

.quantity-popover-container {
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.quantity-popover .quantity {
  background: rgb(255, 255, 255);
}

.quantity {
  color: rgba(var(--color-foreground));
  position: relative;
  width: calc(10rem / var(--font-body-scale) + var(--inputs-border-width));
  width: calc(13rem / var(--font-body-scale) + var(--inputs-border-width) * 2);
  display: flex;
  border-radius: var(--inputs-radius);
  min-height: calc((var(--inputs-border-width) * 2) + 4.5rem);
}

.quantity:before {
  background: rgb(var(--color-background));
  pointer-events: none;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--inputs-radius-outset);
  box-shadow: var(--inputs-shadow-horizontal-offset)
    var(--inputs-shadow-vertical-offset) var(--inputs-shadow-blur-radius)
    rgba(var(--color-shadow), var(--inputs-shadow-opacity));
  z-index: -1;
}

.quantity__button:first-child {
  margin-left: calc(var(--inputs-border-width));
}

.quantity__button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.quantity__button {
  width: calc(3.5rem / var(--font-body-scale));
  flex-shrink: 0;
  font-size: 1.8rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--color-foreground));
  padding: 0;
}

.quantity__button svg {
  width: 1rem;
  pointer-events: none;
}

.quantity__input {
  color: currentColor;
  font-size: 1.6rem;
  font-weight: 500;
  opacity: 0.85;
  text-align: center;
  background-color: transparent;
  border: 0;
  padding: 0 0.5rem;
  width: 100%;
  flex-grow: 1;
  -webkit-appearance: none;
  appearance: none;
}

.quantity:after {
  pointer-events: none;
  content: "";
  position: absolute;
  top: var(--inputs-border-width);
  right: var(--inputs-border-width);
  bottom: var(--inputs-border-width);
  left: var(--inputs-border-width);
  border: 0.1rem solid transparent;
  border-radius: var(--inputs-radius);
  box-shadow: 0 0 0 var(--inputs-border-width)
    rgba(var(--color-foreground), var(--inputs-border-opacity));
  transition: box-shadow var(--duration-short) ease;
  z-index: 1;
}

@media (min-width: 750px) {
  .cart-item .cart-remove-button {
    margin: 0.5rem 0 0 1.5rem;
  }
}
@media screen and (min-width: 750px) and (max-width: 989px) {
  .cart-item .cart-remove-button {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.cart-item .cart-remove-button {
  display: flex;
  margin-left: 1rem;
}

.cart-remove-button .delete {
  min-width: calc(4.5rem / var(--font-body-scale));
  min-height: 4.5rem;
  padding: 0;
  margin: 0 0.1rem 0.1rem 0;
  background-color: rgb(255, 255, 255);
  border: none;
}

@media (min-width: 750px) {
  .cart-remove-button .delete {
    min-width: 3.5rem;
    min-height: 3.5rem;
  }
}
.remove-button {
  font-size: 1.2rem;
}

.cart-remove-button .icon-remove {
  height: 1.5rem;
  width: 1.5rem;
}

/* Total price */

.price {
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  color: rgb(var(--color-foreground));
}

.price--end {
  text-align: right;
}

@media screen and (min-width: 750px) {
  .cart-item__price-wrapper > *:only-child:not(.cart-item__discounted-prices) {
    margin-top: 1rem;
  }
}

.cart-item__price-wrapper > * {
  display: block;
  margin: 0;
  padding: 0;
}

/* Cart-footer- */

.cart-footer-wrapper:last-child .cart-footer {
  padding-bottom: 5rem;
}
@media (min-width: 750px) {
  .cart-footer-wrapper:last-child {
    padding: 0;
  }
}

@media (min-width: 750px) {
  .cart-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    width: 100%;
  }
}

/* @media (min-width:750px){
 .cart-footer>*{
  width:35rem;
 }
} */

.cart-footer-totals {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 30px;
}

@media (min-width: 750px) {
  .cart-footer-totals {
    justify-content: flex-end;
  }
}

.cart-footer-totals > h2 {
  font-size: 1.4rem;
}

.totals-total {
  margin-top: 0.5rem;
}

.cart-footer-totals * {
  line-height: 1;
}

.cart-footer-totals > * {
  margin: 0;
}

.totals-total-value {
  font-size: 1.6rem;
}
.cart-footer-totals > * + * {
  margin-left: 2rem;
}

/* Ending button */
.cart-blocks {
  width: 80%;
}
.cart-blocks > * + * {
  margin-top: 1rem;
}
@media (min-width: 750px) {
  .button-ending-cart {
    display: flex;
    gap: 1rem;
  }
}
.buttton-ending-cart {
  text-align: center;
  display: flex;
  justify-content: flex-end;
}
.button-ending-cart button {
  margin-top: 1rem;
}
.checkout-button {
  /* max-width: 36rem; */
  font-size: 19px;
  padding: 18px 120px 18px 120px;
}
.cart-end-part {
  /* background-color: lightgray; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.quantity {
  width: 172px;
  height: 47px;
  min-height: 0px;
}

.quantity__button {
  height: 47px;
  width: 45px;
}

.quantity__input {
  width: 40px;
  height: 47px;
}

/* Medias for phone */
@media (max-width: 749px) {
  .cart-footer-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cart-footer {
    width: 90%;
  }

  .button-ending-cart {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .button-ending-cart > button {
    width: 100%;
    /* font-size: 17px; */
  }

  .quantity-popover-container {
    padding: 0;
  }
  .title-wrapper-with-link .title {
    font-size: 30px;
  }
  .title-wrapper-with-link {
    align-items: center;
  }
  .whole-div-cart {
    padding-top: 27px;
    padding-bottom: 27px;
  }
  .h4 {
    font-size: 1.2rem;
  }
  .product-option {
    font-size: 1.1rem;
  }
  .price {
    font-size: 1.2rem;
  }

  .cart-footer-totals > h2 {
    font-size: 1.2rem;
  }

  .totals-total-value {
    font-size: 1.3rem;
  }
  .table-cart-items,
  .table-cart-items thead,
  .table-cart-items tbody {
    display: block;
    width: 100%;
  }
  .quantity {
    width: 152px;
    height: 47px;
    min-height: 0px;
  }

  .quantity__button {
    height: 47px;
    width: 45px;
  }

  .quantity__input {
    width: 40px;
    height: 47px;
  }
  .cart-remove-button .delete {
    min-width: 2rem;
  }

  .cart-footer {
    padding-top: 30px;
  }

  .cart-blocks {
    width: 100%;
  }
  .cart-end-part {
    align-items: center;
  }

  .cart-footer-totals {
    align-items: center;
  }

  .checkout-button {
    padding: 15px 80px 15px 80px;
  }
  .cart-item:last-child {
    margin-bottom: 0;
  }

  .cart-item {
    display: grid;
    grid-template: repeat(2, auto) / repeat(4, 1fr);
    gap: 1.5rem;
    margin-bottom: 3.5rem;
  }

  .cart-item__media {
    grid-row: 1 / 3;
  }

  .cart-item__details {
    grid-column: 2 / 4;
  }

  .cart-item__quantity {
    grid-column: 2 / 5;
  }
  /* .cart-item__quantity-wrapper {
  flex-wrap: wrap;
} */

  .cart-item__totals {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .cart-item__image {
    height: auto;
    max-width: calc(6rem / var(--font-body-scale));
  }

  .cart-item__details {
    font-size: 1.4rem;
  }
}

@media (max-width: 400px) {
  .checkout-button {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 749px) {
  .table-cart-items thead tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.2);
    margin-bottom: 4rem;
  }
}

.button-empty {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  line-height: calc(1 + 0.2 / var(--font-body-scale));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0 3rem;
  cursor: pointer;
  font: inherit;
  font-size: 1.5rem;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: box-shadow var(--duration-short) ease;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(18, 18, 18, 1);
  height: 60px;
}

.button-empty::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: var(--buttons-radius-outset);
  box-shadow: 2px;
}
.button-empty::after {
  content: "";
  position: absolute;
  top: var(--buttons-border-width);
  right: var(--buttons-border-width);
  bottom: var(--buttons-border-width);
  left: var(--buttons-border-width);
  z-index: 1;
  border-radius: var(--buttons-radius);
  box-shadow: 0 0 0 calc(var(--buttons-border-width) + var(--border-offset))
      rgba(var(--color-button-text), var(--border-opacity)),
    0 0 0 var(--buttons-border-width)
      rgba(var(--color-button), var(--alpha-button-background));
  transition: box-shadow var(--duration-short) ease;
}
