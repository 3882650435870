
.announcement-bar {
  background-color: black;
  padding: 8px 0;
  text-align: center;
}

.announcement-bar p {
  margin: 0;
  color: #ffffff;
}

.header-divider {
  height: 1px;
  background-color: #e5e5e5;
}

.header {
  top: 0px;
  position: sticky;
  background-color: white;
  z-index: 1000;

}
.header-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.header-nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  height: auto;
}
.header-nav-container {
  display: flex;
  width: 80%;
  height: auto;
  padding: 2px 0;
  align-items: center;
}
.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  margin: 10px 0;
}

.header-logo > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-logo-link {
  text-decoration: none;
  color: #333;
}

/* .header-menu-item {
  margin-left: 20px;
  margin-bottom: 0;
  padding-left: 20px;
  text-align: left;
} */

.header-icons {
  display: flex;
  align-items: center;
}

.header-icon-link {
  text-decoration: none;
  color: #333;
  margin-left: 20px;
}

.header-icon-link .svg-inline--fa {
  font-size: 1.2rem;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  margin-right: 20px;
}

.disable-scroll {
  overflow: hidden;
}

.header-menu.open .header-menu-item {
  margin-bottom: 10px;
  padding: 0;
}

.header-menu-link {
  text-decoration: none;
  color: #333;
  font-size: 1.2em;
  padding-left: 20px;
  display: block;
  padding: 10px 0;
}

.header-menu-link:hover {
  color: #007bff;
}
.cart-icon-link {
  color: #333;
  text-decoration: none;
}

.cart-icon-container {
  position: relative;
  display: block;
  width: 28px;
  height: 28px;
  /* height: auto; */
  /* overflow: hidden; */
}

.material-icons {
  position: relative;
  top: 4px;
  z-index: 1;
  font-size: 24px;
}
.cart-items-count {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 11px;
  border-radius: 50%;
  background: #d60b28;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  text-align: center;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.header-logo-image {
  width: 50px;
  height: auto;
  object-fit: contain;
}

.header-nav-content {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-grow: 1;
  max-width: 1200px;
  /* margin: 0 auto; */
}

.header-menu-item {
  margin-left: 20px;
  margin-bottom: 0;
  padding-left: 20px;
  text-align: left;
  font-size: 0.9em; /* Reduced font size */
}

.header-icons {
  display: flex;
  align-items: center;
}


@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* @media (min-width: 768px) {

} */

@media (min-width: 768px) {
  .header-menu-open {
    display: none;
  }
  .header-menu-closed {
    display: none;
  }

  .header-menu-desktop {
    justify-content: center;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 35vw;
    padding: 0;
  }


}

@media (max-width: 768px) {


  .header-nav {
    position: relative;
    height: auto;
  }

  .hamburger-menu {
    display: block;
    /* position: absolute; */
    /* left: 16px; */
    /* width: 90px; */
    margin: 0;
  }

  .header-logo {
    /* position: absolute; */
  }

  .header-icons {
    /* position: absolute; */
    /* right: 16px; */
  }

  .header-menu-open {
    left: 0;
  }

  .header-menu-link {
    font-size: 1.5rem;
  }
  .header-menu-closed {
    list-style: none;
    margin-right: 35vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 85px;
    background-color: white;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px 20px 20px 0;
    transform: translateX(-100%);
  }

  .header-menu-open {
    list-style: none;
    margin-right: 35vw;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    background-color: #ffffff;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px;
    margin: 0;
    /* transform: translateX(0);
    transition: transform 0.3s ease; */
  }

  .header-menu > li {
    margin-bottom: 10px;
  }
  .header-menu.slide-in {
    animation: slideIn 0.3s forwards;
  }
  .header-menu.slide-out {
    animation: slideOut 0.3s forwards;
  }

  .header-menu-desktop {
    display: none;
  }
}


@media (min-width: 768px) {
  .header-menu-desktop {
    justify-content: center;
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {


  .header {

  }

  .header-nav {
    position: relative;
  }

  .header-nav-container {
    width: 90%;
    /* background-color: lightgreen; */
    /* justify-content: space-between; */
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

  
  .header-nav-container > div:nth-child(1) {
    justify-self: start;
  }
  
  .header-nav-container > div:nth-child(2) {
    justify-self: center;
  }
  
  .header-nav-container > div:nth-child(3) {
    justify-self: end;
  }
  

  .header-menu-closed {
    list-style: none;
    margin-right: 35vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 85px;
    background-color: white;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px 20px 20px 0;
    transform: translateX(-120%);
  }

  .header-menu-open {
    list-style: none;
    margin-right: 35vw;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    background-color: #ffffff;
    width: 80vw;
    height: 100%;
    border-right: 1px solid #e5e5e5;
    box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    padding: 20px;

  }

  .header-nav-content {
    justify-content: flex-end;
    flex-grow: 0;
    width: 90px;
  }
}
