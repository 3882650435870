.chatbox-files-bottom-bar {
  display: flex;
  background-color: rgb(219, 219, 54);
}

.file-uploading-container.file-uploading-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview-container.file-preview-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview.file-chatbox-preview,
.file-preview.uploading-file-preview {
  box-sizing: border-box;
  min-width: 140px;
  min-height: 100px;
  max-width: 140px;
  max-height: 100px;
  margin-bottom: 0px;
  background-color: #fff;
}

.chat-input-main-elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-input-bottom-container {
  /* background-color: lightblue;
overflow: scroll; */
  max-width: 100%;
}

.chatbox-chat-attachments {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0;
  gap: 10px;
}

.chatbox-chat-attachments > .file-preview {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
}

@keyframes jump {
  0%,
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.loader-chat-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-chat-loading-dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #cac7c7; /* or any color you prefer */
  border-radius: 50%;
  animation: jump 0.6s infinite;
}

.loader-chat-loading-dot:nth-child(1) {
  animation-delay: 0.1s;
}

.loader-chat-loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-chat-loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

.attachment-preview {
  position: relative;
  margin-top: 10px;
}

.stacked-thumbnails {
  display: flex;
  align-items: flex-end;
}

.file-thumbnail-preview {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: -100px; /* Negative margin to create the stack effect */
}

.file-thumbnail-preview:last-child {
  margin-right: 0; /* No negative margin for the last item */
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview-icon {
  width: 50%;
  height: 50%;
  object-fit: cover;
}

.file-thumbnail-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 84px;
  height: 40px;
}

.overlay-stacked-files-chat {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  /* background-color: rgb(219 244 215 / 80%); */
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Adjust as needed */
  font-weight: bolder; /* Adjust as needed */
  border-radius: 4px; /* Optional, if you want rounded corners */
}

.show-more-attachments-text {
  width: 132px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-weight: bolder;
}

.show-more-attachments-text > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.chat-input-main-elements,
.chat-input-main-elements-mobile {
  position: relative; /* This ensures the emoji picker is positioned relative to this element */
}

@media (min-width: 900px) {
  .emoji-picker-react {
      position: absolute;
      bottom: 120%; /* Places the bottom of the emoji picker right above the toggle button */
      left: 0%; /* Aligns the emoji picker to the right; adjust as needed */
      z-index: 100; /* Ensures it appears over other elements */
  }
}
@media (max-width: 900px) {
  .emoji-picker-react {
      position: absolute;
      bottom: 100%; /* Adjust as needed to position above the button */
      left: 50%; /* Horizontally center within the parent container */
      transform: translateX(-50%); /* Adjust for the picker's own width */
      z-index: 100; /* Ensure it appears over other elements */
  }

  .emoji-picker-react input {
      font-size: 16px;
  }
}

/* Container holding the chat UI */
.chat-container {
  width: 70%;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 20px 0;
}

/* General tail styling */
.chat-message::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}

/* Tail for sender messages (userCustomer) */
.chat-message.sender::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -35px;
  border-width: 15px 25px 10px 20px;
  border-style: solid;
  border-color: transparent transparent transparent #dbf4d7;
  transform: rotateZ(22deg) translateY(20%);
}

/* Tail for receiver messages */
.chat-message.receiver::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -35px; /* Positioning the tail on the left */
  border-width: 15px 20px 10px 25px; /* Adjusting the border widths for the flip */
  border-style: solid;
  border-color: transparent #e5e5ea transparent transparent; /* Left border colored to match receiver bubble */
  transform: rotateZ(-22deg) translateY(20%); /* Rotating to the left */
}

/* Message from the user (userCustomer, sender) */
.chat-message.sender {
  background-color: #dbf4d7; /* Green for sender */
  align-self: flex-end; /* Align to right */
}

/* Message from the admin (receiver) */
.chat-message.receiver {
  background-color: #e5e5ea; /* Gray for receiver */
  align-self: flex-start; /* Align to left */
}

/* Where the chat messages will appear */
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 500px;
}

/* Individual chat message styling */
.chat-message-input {
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 18px;
  line-height: 1.4;
  font-size: 14px;
  position: relative;
}

/* Chat input section */
.chat-input {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f7f7f7;
  border-top: 1px solid #e3e3e3;
  flex-direction: column;
}

/* Text input for typing a message */
.chat-input input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #ccc;
  outline: none;
}

/* Toggle button to show/hide chat */
.chat-toggle-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-toggle-button:hover {
  background-color: #4cae4c;
}

/* Loading indicator inside the send button when uploading */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555;
}

/* The spinning animation for the loading indicator */
.loading-indicator::before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

/* Media content styles */
.chat-image-checkout-success,
.chat-video-checkout-success {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Admin and user indicator before the message */
.chat-message:before {
  content: attr(data-sender);
  font-size: 12px;
  color: #999;
  position: absolute;
  top: -20px;
  white-space: nowrap;
}

.chat-message.userCustomer:before {
  right: 10px;
}

.chat-message:not(.userCustomer):before {
  left: 10px;
}

.upload-button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
}

.loader-circle-parent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-circle {
  border: 4px solid black;
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 1em;
  height: 1em;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.invisible-text {
  visibility: hidden;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline;
}

.message-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 18px;
  border: 1px solid #ccc;
  outline: none;
}

/* Now with the send button, you should ensure that it is only clickable when there's a message */

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

/* The rest of the CSS remains unchanged. */
@keyframes spinner {
  to {
      transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spinner 0.6s linear infinite;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}
/* ...previous styles... */

/* Improved loading spinner */
.loading-spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #76b852;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
      -webkit-transform: rotate(360deg);
  }
}

/* Updated chat input section for better visibility and spacing */
.chat-input {
  display: flex;
  align-items: center;
  padding: 15px;
  background: #f2f5f8;
  border-top: 1px solid #e0e0e0;
  gap: 10px; /* Ensures elements inside do not stick together */
}

/* Improved button aesthetics */
.send-button {
  padding: 10px 15px;
  background-color: #045fe6;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.send-button:not(:disabled):hover {
  background-color: #05419c;
}

/* The rest of your chat styling can remain the same */

/* ...remaining styles... */

/* Update the loader to be less obtrusive */
.loader {
  margin: 0 auto;
  border-top-color: #76b852;
  animation: load8 1.5s infinite linear;
  width: 30px;
  height: 30px;
  border-width: 3px;
}

.chat-message-input {
  background-color: #f7faff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.chat-message.userCustomer {
  background-color: #dbf4d7;
}

@media (max-width: 900px) {
  .answer > textarea {
      font-size: 16px;
  }
  .checkout-success-main {
      flex-direction: column-reverse;
  }

  .checkout-success-chat-section {
      max-width: 100vw;
  }

  .booking-details-container {
      width: 95%;
  }

  .chat-container {
      width: 95%;
  }

  .chat-input-main-elements {
      display: none;
  }

  .chat-input-main-elements-mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
  }

  .chat-input-main-elements-mobile > div:nth-of-type(1) {
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .chat-input-main-elements-mobile > div:nth-of-type(1) > input {
      font-size: 16px;
      margin: 0;
  }

  .chat-input-main-elements-mobile > div:nth-of-type(2) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
  }
}

@media (min-width: 900px) {
  .chat-input-main-elements-mobile {
      display: none;
  }
}

/* Emoji Picker Toggle Button */
.emoji-toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem; /* Larger emoji size */
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  /* Optional: change background on hover for better UX */
  &:hover {
      background-color: #f0f0f0;
  }
}

.chatbox-files-bottom-bar-chatbox {
  display: flex;
  background-color: rgb(219, 219, 54);
}

.file-uploading-container.file-uploading-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview-container.file-preview-chatbox {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  min-height: 100px;
  margin-top: 0px;
  overflow-y: hidden;
  background-color: #f3f5f8;
  padding: 10px 0px;
}

.file-preview.file-chatbox-preview,
.file-preview.uploading-file-preview {
  box-sizing: border-box;
  min-width: 140px;
  min-height: 100px;
  max-width: 140px;
  max-height: 100px;
  margin-bottom: 0px;
  background-color: #fff;
}

.chat-input-main-elements-chatbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chat-input-bottom-container-chatbox {
  /* background-color: lightblue;
overflow: scroll; */
  max-width: 100%;
}

.chatbox-chat-attachments-chatbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
}

.chatbox-chat-attachments-chatbox > .file-preview {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
}

@keyframes jump {
  0%,
  100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
}

.loader-chat-loading-chatbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-chat-loading-dot-chatbox {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #cac7c7; /* or any color you prefer */
  border-radius: 50%;
  animation: jump 0.6s infinite;
}

.loader-chat-loading-dot-chatbox:nth-child(1) {
  animation-delay: 0.1s;
}

.loader-chat-loading-dot-chatbox:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-chat-loading-dot-chatbox:nth-child(3) {
  animation-delay: 0.3s;
}

.attachment-preview-chatbox {
  position: relative;
  margin-top: 10px;
}

.stacked-thumbnails-chatbox {
  display: flex;
  align-items: flex-end;
}

.file-thumbnail-preview-chatbox {
  min-width: 112px;
  min-height: 80px;
  max-width: 112px;
  max-height: 80px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: -100px; /* Negative margin to create the stack effect */
}

.file-thumbnail-preview-chatbox:last-child {
  margin-right: 0; /* No negative margin for the last item */
}

.preview-image-chatbox {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.preview-icon-chatbox {
  width: 50%;
  height: 50%;
  object-fit: cover;
}

.file-thumbnail-preview-chatbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 84px;
  height: 40px;
}

.overlay-stacked-files-chat-chatbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  /* background-color: rgb(219 244 215 / 80%); */
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Adjust as needed */
  font-weight: bolder; /* Adjust as needed */
  border-radius: 4px; /* Optional, if you want rounded corners */
}

.show-more-attachments-text-chatbox {
  width: 132px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  font-weight: bolder;
}

.show-more-attachments-text-chatbox > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.chat-input-main-elements-chatbox,
.chat-input-main-elements-mobile-chatbox {
  position: relative; /* This ensures the emoji picker is positioned relative to this element */
}

@media (min-width: 900px) {
  .emoji-picker-react {
      position: absolute;
      bottom: 120%; /* Places the bottom of the emoji picker right above the toggle button */
      left: 0%; /* Aligns the emoji picker to the right; adjust as needed */
      z-index: 100; /* Ensures it appears over other elements */
  }
}
@media (max-width: 900px) {
  .emoji-picker-react {
      position: absolute;
      bottom: 100%; /* Adjust as needed to position above the button */
      left: 50%; /* Horizontally center within the parent container */
      transform: translateX(-50%); /* Adjust for the picker's own width */
      z-index: 100; /* Ensure it appears over other elements */
  }

  .emoji-picker-react input {
      font-size: 16px;
  }
}

.file-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.file-preview {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  /* margin-bottom: 10px; */
  border-radius: 4px;
  background-color: #fff;
  /* margin-right: 15px; */
  min-width: 140px;
  min-height: 100px;
  max-width: 140px;
  max-height: 100px;
}
.file-preview-hover {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.file-preview:hover .file-preview-hover {
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  font-weight: bolder;
  margin: 0;
  /* margin-bottom: 10px; */
}
.file-name {
  font-size: 14px;
  font-weight: bolder;
  margin: 0;
  /* margin-bottom: 10px; */
}
.remove-file-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.file-preview.file-chatbox-preview,
.file-preview.uploading-file-preview {
  box-sizing: border-box;
  min-width: 140px;
  min-height: 100px;
  max-width: 140px;
  max-height: 100px;
  margin-bottom: 0px;
  background-color: #fff;
}
.file-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90%;
  justify-content: space-between;
  font-size: 30px;
  color: rgb(62, 62, 62);
}
.file-name {
  font-size: 14px;
  font-weight: bolder;
  margin: 0;
  /* margin-bottom: 10px; */
}
.file-preview-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90%;
}
.file-upload-progress-circle {
  width: 60px;
  height: 60px;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.epr-header {
  margin-top: 30px;
}
