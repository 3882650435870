.main-service-page{
  height: 100%;
  z-index: 1;
  min-height: 100vh;
  background-color: #fff;
}


.product-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  transition: opacity 0.3s ease-in-out;
}

.product-details-container {
  display: flex;
  margin-bottom: 2rem;
}

.product-image-container {
  flex: 1;
  max-width: 600px;
  margin-right: 2rem;
}

.product-image {
  width: 100%;
  height: auto;
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.product-price {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.quantity-container-service-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  border: 1px solid #383333;
  border-radius: 10px;
  padding: 0.5rem;
  width: 150px; /* Adjust the width as needed */
}

.quantity-button-service-page {
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  padding: 0.5rem 1rem;
  user-select: none;
}

.quantity-number-service-page {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #333;
}
.service-duration {
  font-size: 1.2rem; /* Increase font size */
  font-weight: bold; /* Make it bold */
  color: #2a2a2a; /* Use a color that stands out */
 
  display: inline-block; /* Makes the background color wrap the text */
  margin-top: 1rem; /* Add some space above the element */
  width: auto; /* Ensures the background only covers the text */
}



.product-actions {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.add-to-cart {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buy-now {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  background-color: black;
  transition: all 0.3s ease;
}



.buy-now:hover {
  background-color: #333;
}


.product-details {
  font-size: 1rem;
  line-height: 1.5;
}

.similar-products-container {
  margin-top: 3rem;
  border-top: 1px solid #ccc;
  padding-top: 2rem;
}

.similar-products-grid {
  display: flex;
  gap: 2rem; /* Adds consistent gap between grid items */
  overflow-x: auto; /* Add horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
}

.similar-products-grid::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.similar-product {
  width: 24%;
  margin-bottom: 2rem;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
}

.similar-product img {
  width: 100%;
  height: 200px; /* Set a constant height */
  display: block;
  object-fit: cover; /* Scale the images while maintaining their aspect ratio */
  margin-bottom: 0.5rem;
}

.similar-product-content {
  padding: 1rem;
  text-align: left;
}

.similar-product p {
  margin-bottom: 0.5rem;
}

.similar-product button {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid black; /* Black border with 2px thickness */
  font-size: 1rem;
  cursor: pointer;
  color: black; /* Black text */
  background-color: white; /* White background */
  width: 80%; /* Full width of the container */
  height: 40px; /* Fixed height */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: all 0.3s ease;
  border-radius: 5px;
}
.similar-product button:hover{
  
  background-color: #f2f2f2; /* Light gray background on hover for .add-to-cart */
  border-color: #0b0707; /* Darker border on hover */
  border: 2px solid rgb(14, 11, 11); /* Black border for .add-to-cart */
  
}
/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .similar-product {
    width: 80%; /* Adjust the width of product on smaller screens */
    flex: 0 0 auto;
  }
}


@media (max-width: 767px) {
  .product-page {
    padding: 2rem 1rem;
  }

  .product-details-container {
    flex-direction: column;
  }

  .product-image-container {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  height: 70px !important;
}


.add-to-cart, .buy-now {
  padding: 0.5rem 1rem;
  border: none; /* Set to none by default, will add for .add-to-cart below */
  font-size: 1rem;
  cursor: pointer;
  color: black; /* Black text for .add-to-cart */
  background-color: white; /* White background for .add-to-cart */
  width: 60%; /* Adjusted width */
  height: 50px;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 5px;
}

.add-to-cart {
  border: 2px solid rgb(79, 75, 75); /* Black border for .add-to-cart */
  background-color: white; /* White background for .add-to-cart */
  color: black; /* Black text for .add-to-cart */
}

.add-to-cart:hover, .buy-now:hover {
  background-color: #f2f2f2; /* Light gray background on hover for .add-to-cart */
}
.add-to-cart:hover {
  background-color: #f2f2f2; /* Light gray background on hover for .add-to-cart */
  border-color: #0b0707; /* Darker border on hover */
  border: 2px solid rgb(14, 11, 11); /* Black border for .add-to-cart */
}

.buy-now {
  color: white; /* White text for .buy-now */
  background-color: black; /* Black background for .buy-now */
}

.buy-now:hover {
  background-color: #333; /* Darker background on hover for .buy-now */
}

/* Responsive styles */
@media (max-width: 768px) {
  .add-to-cart, .buy-now {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 1.5rem; /* Smaller margin on smaller screens */
    height: 40px; /* Smaller height on smaller screens */
    padding: 0.5rem; /* Adjust padding on smaller screens */
  }
}

/* Additional responsive consideration for very small screens */
@media (max-width: 480px) {
  .add-to-cart, .buy-now {
    font-size: 0.9rem; /* Smaller font size on very small screens */
  }
}
